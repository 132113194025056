import React, { FunctionComponent } from 'react';
import { InternalLink } from '../../internal-link/internal-link';
import styles from './footer-link.module.scss';

export interface IFooterLinkProps {
  linkText: string;
  url: string;
  customUrl?: string;
}

export const FooterLink: FunctionComponent<IFooterLinkProps> = ({
  linkText,
  url,
  customUrl
}) => {
  return (
    <React.Fragment>
      {customUrl ? (
        <a
          href={customUrl}
          className={styles.footerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </a>
      ) : (
        <InternalLink url={url} className={styles.footerLink}>
          {linkText}
        </InternalLink>
      )}
    </React.Fragment>
  );
};
