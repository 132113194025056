import React, { FunctionComponent } from 'react';
import styles from './footer-extras.module.scss';
import {
  ImageFeatureWithLink,
  IImageFeatureWithLinkProps
} from '../../image-feature-with-link/image-feature-with-link';
import {
  SocialMediaPanel,
  ISocialMediaPanelProps
} from '../../social-media-panel/social-media-panel';
import { FooterLink, IFooterLinkProps } from '../footer-link/footer-link';

export interface IFooterExtrasProps {
  linkImagesTitle: string;
  linkImages: IImageFeatureWithLinkProps[];
  socialMedia: ISocialMediaPanelProps;
  bottomLink: IFooterLinkProps[];
  logo: string;
}

export const FooterExtras: FunctionComponent<IFooterExtrasProps> = ({
  linkImagesTitle,
  linkImages,
  socialMedia,
  bottomLink,
  logo
}) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.logo} src={logo} alt="logo" />
      <div className={styles.linkImagesWrapper}>
        <h3 className={styles.linkImagesTitle}>{linkImagesTitle}</h3>
        <div className={styles.linkImages}>
          {linkImages.map((linkImage: IImageFeatureWithLinkProps, i) => (
            <ImageFeatureWithLink {...linkImage} key={i} />
          ))}
        </div>
      </div>
      <div className={styles.socialMedia}>
        <SocialMediaPanel {...socialMedia} />
      </div>
      <div className={styles.bottomLink}>
        {bottomLink.map((link: IFooterLinkProps, i) => (
          <FooterLink {...link} key={i} />
        ))}
      </div>
    </div>
  );
};
