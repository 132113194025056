import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';
import { IJobOffertItemProps } from '../components/active-job-offerts/job-offert-item/job-offert-item';

export interface IJobOffertProps extends IJobOffertItemProps {
  requirements: string;
  weOffer: string;
  traffitLink: string;
  applyButtonText: string;
}

export class JobOffertModel extends ContentItem {
  get postition(): string {
    return get(this._config, 'elements.postition.value', '');
  }

  get city(): string {
    return get(this._config, 'elements.city.value', '');
  }

  get region(): string {
    return get(this._config, 'elements.region.value', '');
  }

  get requirements(): string {
    return get(this._config, 'elements.requirements.value', '');
  }

  get weOffer(): string {
    return get(this._config, 'elements.we_offer.value', '');
  }

  get url(): string {
    return get(this._config, 'elements.basic_page_settings__url.value', null);
  }

  get traffitLink(): string {
    return get(this._config, 'elements.traffit_link.value', '');
  }

  get applyButtonText(): string {
    return get(this._config, 'elements.apply_button_text.value', 'Apply');
  }

  public static create(data: IJobOffert): IJobOffertProps {
    const {
      postition,
      city,
      region,
      url,
      requirements,
      weOffer,
      traffitLink,
      applyButtonText
    } = new JobOffertModel(data);
    return {
      postition,
      city,
      region,
      url,
      requirements,
      weOffer,
      traffitLink,
      applyButtonText
    };
  }
}
