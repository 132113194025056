import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';
import { Models } from './index';
import { KENTICO_TYPES } from '../const/common/kentico';
import { ButtonModel } from './button-model';
import { IButtonProps } from '../components/button/button';
import { ILinkProps, INavbarProps } from '../components/navbar/navbar';
import { IInternalLinkProps } from '../components/internal-link/internal-link';
import { IDropDownLinkProps } from '../components/dropdown-link/dropdown-link';

export class NavbarModel extends ContentItem {
  get button(): IButtonProps {
    const button = get(this._config, 'elements.button.value[0]', []);
    const modeledButton = button && ButtonModel.create(button);
    return modeledButton;
  }

  get links(): ILinkProps[] | null {
    const resolvedObject = [] as ILinkProps[];
    const data = get(this._config, 'elements.navbar_links.value', null);
    if (Array.isArray(data) && data.length > 0) {
      data.map((linkedItem, list) => {
        const typeComponent = linkedItem.system.type;
        let modeledData;
        switch (typeComponent) {
          case KENTICO_TYPES.INTERNAL_LINK:
            modeledData = Models.InternalLinkModel.create(
              linkedItem as IInternalLink
            );
            return (resolvedObject[list] = modeledData) as IInternalLinkProps;
          case KENTICO_TYPES.DROPDOWN_LIST:
            modeledData = Models.DropDownListModel.create(
              linkedItem as IDropDownList
            );
            const childrenModel = modeledData.children.map((child, n) =>
              Models.InternalLinkModel.create(child as IInternalLink)
            );
            return (resolvedObject[list] = {
              name: modeledData.name,
              children: childrenModel
            } as IDropDownLinkProps);
          default:
            return null;
        }
      });
      return resolvedObject;
    }
    return null;
  }

  get navbarTransparent(): boolean {
    return (
      get(
        this._config,
        'elements.transparent_background.value[0].codename',
        false
      ) === 'true'
    );
  }

  get showSection(): string {
    return get(this._config, 'elements.show_section.value', '');
  }

  get hideSection(): string {
    return get(this._config, 'elements.hide_section.value', '');
  }

  public static create(data: INavbar): INavbarProps {
    const {
      button,
      links,
      showSection,
      hideSection,
      navbarTransparent
    } = new NavbarModel(data);
    return {
      links,
      button,
      showSection,
      hideSection,
      navbarTransparent
    };
  }
}
