import React, { FunctionComponent } from 'react';
import styles from './footer.module.scss';
import {
  FooterLinksColumn,
  IFooterLinksColumnProps
} from './footer-links-column/footer-links-column';
import { Container } from '../container/container.component';
import { FooterLink } from './footer-link/footer-link';
import {
  FooterExtras,
  IFooterExtrasProps
} from './footer-extras/footer-extras';
import {
  IItemsInRowComponentProps,
  ItemsInRowComponent
} from '../items-in-row/items-in-row';
import { useLatestArticles } from '../../hooks/use-latest-articles';
import { IBlogArticleUrl } from '../../models/blog-article-url-model';
import { blogArticleUrl } from '../../utils/utils';

export interface IFooterProps {
  linkColumns: IFooterLinksColumnProps[];
  bottomText: string;
  footerExtras: IFooterExtrasProps;
  awards?: IItemsInRowComponentProps;
  type: string;
}

export const Footer: FunctionComponent<IFooterProps> = ({
  linkColumns,
  bottomText,
  footerExtras,
  type,
  awards
}) => {
  const latestBlogArticles = useLatestArticles();

  return (
    <footer
      className={`${styles.footer} ${type === 'awards' &&
        styles.footerAwards} ${type === 'copyrights' &&
        styles.footerCopyrights}`}
    >
      <Container>
        {type === 'links' && (
          <div className={styles.footerWrapper}>
            <div className={styles.footerTop}>
              <div className={styles.footerLinkColumns}>
                {linkColumns.map((column: IFooterLinksColumnProps, i) => (
                  <FooterLinksColumn {...column} key={i} />
                ))}
              </div>
              <div className={styles.footerExtras}>
                <FooterExtras {...footerExtras} />
              </div>
            </div>
            <div className={styles.footerBlogLinksWrapper}>
              <h3 className={styles.blogTitle}>Blog</h3>
              <div className={styles.footerBlogLinks}>
                {latestBlogArticles.map((link: IBlogArticleUrl, i) => (
                  <FooterLink
                    linkText={link.title}
                    url={blogArticleUrl(link.slug, link.category)}
                    key={i}
                  />
                ))}
              </div>
            </div>
            <div className={styles.footerBottom}>
              &copy; {new Date().getFullYear()}
              <span className={styles.bottomText}>{bottomText}</span>
            </div>
          </div>
        )}
        {type === 'awards' && (
          <div className={styles.footerAwards}>
            {
              <React.Fragment>
                <div className={styles.footerBottom}>
                  &copy; {new Date().getFullYear()}
                  <span className={styles.bottomText}>{bottomText}</span>
                </div>
                <div className={styles.awards}>
                  {awards && <ItemsInRowComponent {...awards} />}
                </div>
              </React.Fragment>
            }
          </div>
        )}
        {type === 'copyrights' && (
          <div className={styles.textCopyrights}>
            &copy; {new Date().getFullYear()}
            <span className={styles.bottomText}>{bottomText}</span>
          </div>
        )}
      </Container>
    </footer>
  );
};
