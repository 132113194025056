import { useStaticQuery, graphql } from 'gatsby';
import {
  BlogArticleUrlModel,
  IBlogArticleUrl
} from '../models/blog-article-url-model';
import { filterById } from '../utils/utils';

export const useLatestArticles = (): IBlogArticleUrl[] => {
  const { allKontentItemBlogArticle } = useStaticQuery(
    graphql`
      query {
        allKontentItemBlogArticle(
          limit: 7
          sort: { fields: elements___publication_date___value, order: DESC }
        ) {
          nodes {
            ...FragmentBlogArticleUrl
          }
        }
      }
    `
  );
  const filteredArticles = filterById(allKontentItemBlogArticle.nodes);

  return filteredArticles.map((article: IBlogArticleUrl, i: number) =>
    BlogArticleUrlModel.create(article)
  );
};
