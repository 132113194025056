import { FooterModel } from '../models/footer-model';
import { seoDataResolver } from '../components/seo-component/seoDataResolver';
import { INavbarProps } from '../components/navbar/navbar';
import { ISeoComponentProps } from '../components/seo-component/seo-component';
import { IFooterProps } from '../components/footer/footer';
import { NavbarModel } from '../models/navbar-model';

interface IPageDataResolver {
  navbarData: INavbarProps;
  seoData: ISeoComponentProps | null;
  footerData: IFooterProps;
}

export const pageDataResolver = (data): IPageDataResolver => {
  const navbarData = NavbarModel.create(data.elements.navbar.value[0]);
  const seoData = data.elements ? seoDataResolver(data.elements) : null;

  const footerData = FooterModel.create(data.elements.footer);

  return {
    navbarData,
    seoData,
    footerData
  };
};
