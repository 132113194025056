import { graphql } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  useContext,
  useEffect,
  FunctionComponent,
  useMemo
} from 'react';
import get from 'lodash/get';
import { Navbar } from '../components/navbar/navbar';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import { PageProps } from 'gatsby';
import { SeoComponent } from '../components/seo-component/seo-component';
import { Footer } from '../components/footer/footer';
import { pageDataResolver } from '../utils/pageDataResolver';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import '../styles/global.css';
import '../styles/reset.css';
import { seoDataResolver } from '../components/seo-component/seoDataResolver';
import { Container } from '../components/container/container.component';
import { ActiveJobOfferts } from '../components/active-job-offerts/active-job-offerts';
import { ActiveJobOffertsModel } from '../models/active-job-offerts-model';
import styles from '../styles/job-offert.module.scss';
import { JobOffertModel } from '../models/job-offert-model';
import { JobOffertHero } from '../components/job-offert-hero/job-offert-hero';
import { useGlobalTranslations } from '../hooks/use-global-translations';
import { PageSectionModel } from '../models/page-section-model';
import { PageSection } from '../components/page-section/page-section';
import { Button } from '../components/button/button';
import { withCookieBar } from '../hoc/withCookieBar';

interface IData {
  kontentItemJobOffert: IJobOffert;
  kontentItemActiveJobOfferts: IActiveJobOfferts;
  kontentItemPage: IPage;
  careerPage: {
    elements: {
      basic_page_settings__title: {
        value: string;
      };
      basic_page_settings__url: {
        value: string;
      };
    };
  };
  benefitsSection: IPageSection;
  cookieSection: ICookieData;
}

type JobOffertPageProps = PageProps<IData, IPageContext>;

const JobOffert: React.FunctionComponent<JobOffertPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);
  const {
    jobOffertRequirementsTitle,
    weOfferTitle,
    joinOurAwesomeTeam
  } = useGlobalTranslations();

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();
    const debouncedResize = debounce(handleResize, 300);

    debouncedResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    props.data.cookieSection.elements.button_cta.value,
    props.data.cookieSection.elements.text.value,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    state.showActiveDevice,
    state.showPageState.currentUri
  ]);

  const jobOffertData = useMemo(
    () => JobOffertModel.create(props.data.kontentItemJobOffert),
    [props.data.kontentItemJobOffert]
  );

  const filteredActiveJobOfferts = useMemo(
    () =>
      props.data.kontentItemActiveJobOfferts.elements.job_offerts
        ? props.data.kontentItemActiveJobOfferts.elements.job_offerts.value.filter(
            (jobOffer: IJobOffertItem) =>
              jobOffer.system.id !== props.pageContext.id
          )
        : null,
    [
      props.data.kontentItemActiveJobOfferts.elements.job_offerts,
      props.pageContext.id
    ]
  );

  const activeJobOffertsData = useMemo(
    () =>
      filteredActiveJobOfferts
        ? ActiveJobOffertsModel.create({
            ...props.data.kontentItemActiveJobOfferts,
            elements: {
              ...props.data.kontentItemActiveJobOfferts.elements,
              // eslint-disable-next-line @typescript-eslint/camelcase
              job_offerts: { value: filteredActiveJobOfferts }
            }
          })
        : null,
    [filteredActiveJobOfferts, props.data.kontentItemActiveJobOfferts]
  );

  const seoData = useMemo(
    () =>
      props.data.kontentItemJobOffert.elements
        ? seoDataResolver(props.data.kontentItemJobOffert.elements)
        : null,
    [props.data.kontentItemJobOffert.elements]
  );

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  const careerPageTitle = useMemo(() => {
    const title = get(
      props.data.careerPage,
      'elements.basic_page_settings__title.value'
    );
    return title.split('-')[0] || title;
  }, [props.data.careerPage]);

  const careerPageUrl = useMemo(() => {
    return (
      get(props.data.careerPage, 'elements.basic_page_settings__url.value') ||
      null
    );
  }, [props.data.careerPage]);

  const benefitsData = useMemo(() => {
    return PageSectionModel.create(props.data.benefitsSection);
  }, [props.data.benefitsSection]);

  const getPostionText = useMemo(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const htmlElem = document.createElement('span');
    htmlElem.innerHTML = jobOffertData.postition;

    return htmlElem.innerText;
  }, [jobOffertData.postition]);

  return (
    <>
      <SeoComponent {...seoData} />
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <div className={styles.hero}>
        <JobOffertHero
          careerPageUrl={careerPageUrl}
          careerPageTitle={careerPageTitle}
          jobOffertData={jobOffertData}
          positionText={getPostionText}
        />
        <div className={styles.jobOffert}></div>
      </div>
      <div className={styles.jobOffertContent}>
        <Container>
          <div className={styles.contentWrapper}>
            <div className={styles.listWrapper}>
              <h2 className={styles.listTitle}>{jobOffertRequirementsTitle}</h2>
              <div
                className={styles.list}
                dangerouslySetInnerHTML={{ __html: jobOffertData.requirements }}
              ></div>
            </div>
            <div className={styles.listWrapper}>
              <h2 className={styles.listTitle}>{weOfferTitle}</h2>
              <div
                className={styles.list}
                dangerouslySetInnerHTML={{ __html: jobOffertData.weOffer }}
              ></div>
            </div>
          </div>
        </Container>
      </div>
      <PageSection {...benefitsData} />
      <div className={styles.ctaSection}>
        <div className={styles.ctaSectionContent}>
          <h3 className={styles.ctaSectionHeader}>{joinOurAwesomeTeam}</h3>
          <a
            className={styles.ctaSectionLink}
            href={jobOffertData.traffitLink}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              border="rectangular"
              size="large"
              CTA={true}
              theme="transparent"
              text={jobOffertData.applyButtonText.toUpperCase()}
            />
          </a>
        </div>
      </div>
      {activeJobOffertsData && (
        <div className={styles.activeJobOffertsContainer}>
          <ActiveJobOfferts
            applyButtonText={activeJobOffertsData.applyButtonText || ''}
            jobOfferts={activeJobOffertsData.jobOfferts}
            headerValue={activeJobOffertsData.headerValue}
          />
        </div>
      )}
      {pageData.footerData.linkColumns.length && (
        <Footer {...pageData.footerData} />
      )}
    </>
  );
};

export default withAppStore(withCookieBar(JobOffert as FunctionComponent<{}>));

export const query = graphql`
  query($pageLanguage: String, $id: String) {
    kontentItemPage(
      system: {
        language: { eq: $pageLanguage }
        codename: { eq: "crm_software_development" }
      }
    ) {
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    careerPage: kontentItemPage(
      system: { codename: { eq: "career" }, language: { eq: $pageLanguage } }
    ) {
      elements {
        basic_page_settings__title {
          value
        }
        basic_page_settings__url {
          value
        }
      }
    }
    kontentItemJobOffert(
      system: { language: { eq: $pageLanguage }, id: { eq: $id } }
    ) {
      ...FragmentJobOffert
    }
    kontentItemActiveJobOfferts(
      system: { language: { eq: $pageLanguage } }
      elements: {
        job_offerts: {
          value: {
            elemMatch: {
              id: { ne: $id }
              system: { language: { eq: $pageLanguage } }
            }
          }
        }
      }
    ) {
      ...FragmentActiveJobOffers
    }
    benefitsSection: kontentItemPageSection(
      system: {
        codename: { eq: "benefits_job_offert" }
        language: { eq: $pageLanguage }
      }
    ) {
      ...FragmentPageSection
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
