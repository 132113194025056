import React, { FunctionComponent } from 'react';
import { useGlobalTranslations } from '../../hooks/use-global-translations';
import { IJobOffertProps } from '../../models/job-offert-model';
import { Button } from '../button/button';
import { Container } from '../container/container.component';
import { InternalLink } from '../internal-link/internal-link';
import styles from './job-offert-hero.module.scss';

interface IJobOffertHero {
  careerPageUrl: string;
  careerPageTitle: string;
  jobOffertData: IJobOffertProps;
  positionText: string;
}

export const JobOffertHero: FunctionComponent<IJobOffertHero> = ({
  careerPageTitle,
  careerPageUrl,
  jobOffertData
}) => {
  const { jobOffertApplication } = useGlobalTranslations();

  return (
    <Container>
      <div className={styles.heroWrapper}>
        <InternalLink
          className={styles.linkBack}
          url={careerPageUrl ? careerPageUrl : '#'}
        >
          {careerPageTitle}
        </InternalLink>
        <div className={styles.heroTitle}>
          <div
            dangerouslySetInnerHTML={{ __html: jobOffertData.postition }}
          ></div>
          <p className={styles.city}>{jobOffertData.city}</p>
          <p className={styles.region}>{jobOffertData.region}</p>
        </div>
        <a
          href={jobOffertData.traffitLink}
          className={styles.heroButton}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className={styles.button}
            border="rectangular"
            size="large"
            CTA={true}
            text={jobOffertApplication}
            theme="transparent"
            transofrmUppercase={true}
          />
        </a>
      </div>
    </Container>
  );
};
