import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';

export interface IBlogArticleUrl {
  title: string;
  slug: string;
  category: string;
}

export class BlogArticleUrlModel extends ContentItem {
  get slug(): string {
    return get(this._config, 'elements.basic_page_settings__url.value', '');
  }

  get title(): string {
    return get(this._config, 'elements.title_value.value', '');
  }

  get category(): string {
    return get(
      this._config,
      'elements.blog_category.value[0].elements.category_url_value.value',
      ''
    );
  }

  public static create(data: IBlogArticleUrl): IBlogArticleUrl {
    const { slug, title, category } = new BlogArticleUrlModel(data);

    return {
      slug,
      title,
      category
    };
  }
}
