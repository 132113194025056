import React, { FunctionComponent } from 'react';
import styles from './footer-links-column.module.scss';
import { FooterLink, IFooterLinkProps } from '../footer-link/footer-link';

export interface IFooterLinksColumnProps {
  columnTitle: string;
  links: IFooterLinkProps[];
}

export const FooterLinksColumn: FunctionComponent<IFooterLinksColumnProps> = ({
  columnTitle,
  links
}) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{columnTitle}</h3>
      <div className={styles.links}>
        {links.map((link: IFooterLinkProps, i) => (
          <FooterLink {...link} key={i} />
        ))}
      </div>
    </div>
  );
};
